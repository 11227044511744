<label
  class="base-input-label w-full"
  [ngClass]="{ 'opacity-50': disabled || formControl?.disabled }"
>
  {{ label }} {{ required && "*" }}
  @if (formControl) {
    <input
      [matDatepicker]="picker"
      (click)="picker.open()"
      [formControl]="formControl"
      [placeholder]="placeholder"
      class="base-text-input"
      [ngClass]="{
        'background-grey': formControl?.disabled,
        'border-red-200': formControl.dirty && formControl.invalid
      }"
    />
  } @else {
    <input
      [matDatepicker]="picker"
      [(ngModel)]="value"
      [required]="required"
      (click)="picker.open()"
      [placeholder]="placeholder"
      class="base-text-input"
      [disabled]="disabled"
      [name]="name + '-datepicker'"
    />
  }
  <a
    (click)="picker.open()"
    [ngClass]="{ 'datepicker-toggle': label, 'datepicker-toggle-no-label': !label }">
    <mat-icon class="md-18 text-gray-light">calendar_today</mat-icon>
  </a>
  <mat-datepicker #picker></mat-datepicker>
</label>
