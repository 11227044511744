import { CommonModule } from "@angular/common";
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "app-date-picker",
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatIconModule, MatDatepickerModule, MatNativeDateModule],
  templateUrl: "./date-picker.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ],
  styles: [
    `
           :host {
             position: relative;
           }
           :host .datepicker-toggle-no-label {
             position: absolute;
             top: 0.9rem;
             right: 0.8rem;
           }
           :host .datepicker-toggle {
             position: absolute;
             top: 2.2rem;
             right: 0.8rem;
           }
         `
  ]
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
  @Input() required: boolean;
  @Input() label: string;
  @Input() fullWidth: boolean;
  @Input() placeholder: string;
  @Input() formControl: UntypedFormControl;
  @Input() disabled: boolean;
  @Output() onchange: any = new EventEmitter();
  @Input() name: string;

  onChange: any = val => {
    this.onchange.emit(val);
  };
  onTouch: any = () => {};
  @Input("value") _value;
  set value(value: string) {
    this._value = value;
    this.onChange(value);
    this.onTouch(value);
  }
  get value() {
    return this._value;
  }

  writeValue(value: any): void {
    if (value) this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  fullReset() {
    this._value = "";
  }

  constructor() {}

  ngOnInit(): void {}
}
